export const Songs = [
	{
		name: "First song",
		location: "songs/Jai_Lakshmi_Mata_I_Hindi_Lyrics_I__(getmp3.pro).mp3",
		img: "https://i.ytimg.com/vi/dza5NZTExYo/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLBg6RCPYRjt6CcWywZk4Z0l8WFItQ",
	},
	{
		name: "Second song",
		location: "songs/Jai_Ganesh_Jai_Ganesh_Deva_Full_So_(getmp3.pro).mp3",
		img: "https://i.ytimg.com/vi/sfsKlfH7K7Y/hqdefault.jpg?sqp=-oaymwEbCKgBEF5IVfKriqkDDggBFQAAiEIYAXABwAEG&rs=AOn4CLDF1ttpd0ruzO37Q8U4wQGQbtlyRA",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
	{
		name: "Second song",
		location: "",
		img: "https://flowbite.com/docs/images/people/profile-picture-3.jpg",
	},
];
