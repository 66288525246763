import React from "react";
import { Songs } from "../Utils/Data";
import Card from "./Card";

function Main() {
	return (
		<div className="px-20 py-10">
			<div className="bg-gray-200 p-5 rounded-2xl shadow-2xl grid grid-cols-4 gap-4">
				{Songs.map((song) => (
					<Card {...song} />
				))}
			</div>
		</div>
	);
}

export default Main;
