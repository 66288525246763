import React, { useEffect, useMemo, useState } from "react";

function Card({ name, location, img }) {
	const audio = useMemo(() => {
		if (typeof Audio != "undefined") {
			return new Audio(location);
		}
	}, [location]);
	const [playing, setPlaying] = useState(false);

	const toggle = () => setPlaying(!playing);
	useEffect(() => {
		playing ? audio.play() : audio.pause();
		//eslint-disable-next-line
	}, [playing]);

	useEffect(() => {
		audio.addEventListener("ended", () => setPlaying(false));
		return () => {
			audio.removeEventListener("ended", () => setPlaying(false));
		};
		//eslint-disable-next-line
	}, []);

	return (
		<div className="max-w-sm rounded-lg border  shadow-md bg-gray-800 border-gray-700">
			<div className="flex flex-col items-center py-10">
				<img
					className="mb-3 w-24 h-24 rounded-full shadow-lg"
					src={img}
					alt="Bonnie image"
				/>
				<h5 className="mb-1 text-xl font-medium text-white">{name}</h5>
				<div className="flex mt-4 space-x-3 lg:mt-6">
					<button
						onClick={toggle}
						className="inline-flex items-center py-2 px-4 text-sm font-medium text-center text-white  rounded-lg  focus:ring-4 focus:outline-none  bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
					>
						{playing ? "Pause" : "Play"}
					</button>
					<button className="inline-flex items-center py-2 px-4 text-sm font-medium text-center rounded-lg border focus:ring-4 focus:outline-none  bg-gray-800 text-white border-gray-600 hover:bg-gray-700 hover:border-gray-700 focus:ring-gray-700">
						Download
					</button>
				</div>
			</div>
		</div>
	);
}

export default Card;
